import { useContext, useEffect } from "react";
import { PlatformContext } from "../../context/platformContext";
import { types } from "../../types/types";
import { confirm, success } from "../../services/alertsServices";
import "../../styles/card.css";
import { DataVikua } from "../../interfaces/dataVikua";
import { DeleteOutlined, EditOutlined, SendOutlined } from "@ant-design/icons";
import { postData } from "../../services/ticketsServices";


const CardBoard = ({ data, role }: { data: DataVikua; role: any }) => {
 
  const { platformState, dispatch }: any = useContext(PlatformContext);

  useEffect(() => {
  
  }, [role]);

 

  function uploadData(item: any) {
    dispatch({
      type: types.botonTitle,
      payload: platformState.language.userFormBotonEditar,
    });
    dispatch({
      type: types.botonTitle,
      payload: platformState.language.userFormBotonEditar,
    });

    dispatch({
      type: types.title,
      payload: platformState.language.modalBoardsEditar,
    });
    dispatch({
      type: types.openModal,
      payload: true,
    });
    return dispatch({
      type: types.dataUpdate,
      payload: item,
    });
  }


  const deleteBoard = async () => {
    confirm(data.uuid, dispatch, "dataVikua");
  };

  const  createTicket = async(item: any) => {
    try {
      let user = JSON.parse(localStorage.getItem("session") || "")
      let env = {
        title: "Solicitud de data",
        description: `El usuario ${user.name} esta solicitando la data de ${item.title}`,
        date: Date.now(),
        image:null,
        status: "solicitado",
        user: user.idU
      }
    
      const send = await postData(env)
      console.log(send)
      return success(send.message) 
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className="card">
      <div className="row">
        <div className="col-2">
          <div className="content-image">
             <img src={data.image} className="image" alt="images" />
          </div>
         


        </div>
        <div className="col-9">
          <h6 className="card-title">{data.title}</h6>
          <p className="card-text sub">{data.company}</p>
          <p className="card-text">{data.description}</p>
          
          {role > 2 ? <div className="row " style={{textAlign: "end"}}>
                         <div className="col-8"></div>
                      <div className="col " >
                        <button className="btn boton-data">
                        <EditOutlined
                            key="setting"
                            onClick={() => {return uploadData(data)  }}
                            size={20}
                          />,
               
                        </button>
                      </div>
                      <div className="col">
                        <button className="btn boton-data">
                 
                            <DeleteOutlined  key={"delete"} onClick={()=>{return deleteBoard()}}/>
                        </button>
                      </div>
                    
                    </div>
                    : <div className="row " style={{textAlign: "end"}}>
                    <div className="col-10"></div>
               
                 <div className="col">
                   <button className="btn boton-data">
               
                       <SendOutlined key={"sens"} onClick={()=>{return createTicket(data) }}/>
                   </button>
                 </div>
               </div>
          }
        </div>
      </div>
    </div>
  );
};

export default CardBoard;
