
import {api} from '../environment';
import { Tracking } from '../interfaces/tracking';
import { types } from '../types/types';
import { error } from './alertsServices';
import { postTracking } from './tracking';

let tracking: Tracking = {
  uuidSesion: "",
  activity: "",
  description: ""
}

const getMyBoards = async(limit: any, skip: any, search: any) => {
  let token = localStorage.getItem('token') 
  let sessionTem = localStorage.getItem('session') || ""
  let {session} = JSON.parse(sessionTem)
  let options = {  
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}` ,
      'session': `${session}`
    }
  }

      try{
       tracking.activity = "Get MyBoards"
        tracking.description = "Get all myboards"
        tracking.uuidSesion = session
        await postTracking(tracking)
    
        const login = await fetch(`${api}myboards?limit=${limit}&offset=${skip}&search=${search}`, options);
        const data = await login.json();
        return data;
      }catch(e){
        return e;
      }
} 
const postMyBoards = async(data: any) => {
  
  let token = localStorage.getItem('token') 
  let sessionTem = localStorage.getItem('session') || ""
  let {session} = JSON.parse(sessionTem)
  let options = {  
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}` ,
      'session': `${session}`
    },
    body: JSON.stringify(data)
  }

      try{
       
        tracking.activity = "Create MyBoards"
        tracking.description = "Create a new myboards"
        tracking.uuidSesion = session
        await postTracking(tracking)
        const login = await fetch(api+"myboards", options
        );
        const data = await login.json();
        return data;
      }catch(e){
        return e;
      }
} 

const updateMyBoards = async (data: any, id: string) => {

  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') 
  if (!sessionTem) return
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    },
    body: JSON.stringify(data)
  }

  try {

    tracking.activity = "Update Boards"
    tracking.description = "Update a board"
    tracking.uuidSesion = session
    await postTracking(tracking)
    const login = await fetch(api + "myboards/" + id, options
    );
    const data= await login.json();
    return data;
  } catch (e) {
    return e;
  }
}

const deleteMyBoard = async (id: string, dispatch: any) => {
  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') || ""
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    }
  }

  try {
    tracking.activity = "Delete Board"
    tracking.description = "Delete a Borad"
    tracking.uuidSesion = session
    await postTracking(tracking)

    const login = await fetch(api + "myboards/" + id, options
    );
    const data = await login.json();
    if (data.error) {

    } else {
      dispatch({
        type: types.test,
        payload: Math.random()
      })
    }

    return data;
  } catch (e) {

    error("No se elimino el board")
    return e;
  }
}
export {
    getMyBoards, postMyBoards, updateMyBoards, deleteMyBoard
}