import { Avatar, Button, Drawer, Form, Input, Select } from "antd";
import { IconHeader } from "../../components/icons/iconHeader";
import { useContext, useEffect, useState } from "react";
import { PlatformContext } from "../../context/platformContext";
import { EditOutlined } from "@ant-design/icons";
import { useCompanies } from "../../hooks/companiesHook";
import { avatar } from "../../environment";
import { useRolesForUser } from "../../hooks/rolesForUserHook";
import {
  getBoardsForId,
  getSubCompanies,
} from "../../services/companiesServices";
import { postUsers, putUsers } from "../../services/usersServices";
import { error } from "../../services/alertsServices";
import DataLabelsAsig from "../../components/datalabels/dataLabelsAsig";
import { DataLabelsUser } from "../../interfaces/dataLabelsUser";
import { getDataForId } from "../../services/dataVikuaServices";

const FormsUsers = ({ close }: any) => {
  const { rolesUser } = useRolesForUser();
  const { companies } = useCompanies();
  const { platformState }: any = useContext(PlatformContext);
  const [form] = Form.useForm();
  const [image, setImage] = useState(avatar);
  const [dataLabels, setDataLabels] = useState<any>([]);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [subcompanies, setSubcompanies] = useState([]);
  const [boards, setBoards] = useState<any>([]);
  const [project, setProject] = useState("none");

  useEffect(() => {
    if (platformState.dataUpdate) {
      setImage(platformState.dataUpdate.image);
      form.setFieldValue("name", `${platformState.dataUpdate.name}`);
      form.setFieldValue("lastname", `${platformState.dataUpdate.lastname}`);
      form.setFieldValue("email", platformState.dataUpdate.email);
      form.setFieldValue("area", `${platformState.dataUpdate.area}`);
      form.setFieldValue("role", `${platformState.dataUpdate.role}`);
      form.setFieldValue("fecha", `${platformState.dataUpdate.createAt}`);
      form.setFieldValue("status", platformState.dataUpdate.status);

      setDataLabels(platformState.dataUpdate.boards);
    } else {
      limpiar();
    }
  }, [platformState.openModal]);
  const action = async () => {
    if (!platformState.dataUpdate) return await sendData();
    return await actualizarData();
  };
  const validarItem = async () => {
    let { project } = form.getFieldsValue();
    let array = [];
    if (dataLabels.length === 0) {
      return [project];
    } else {
      for (let i in dataLabels) {
        array.push(dataLabels[i]?.uuidEmpresa);
      }
      return await array;
    }
  };

  const sendData = async () => {
    let { email, name, lastname, role, fecha, status, area } =
      form.getFieldsValue();
    const newCompanies = await validarItem();
    let uuid = JSON.parse(localStorage.getItem("session") || "");
    let env = {
      email: email,
      password: "123456",
      name: name,
      lastname: lastname,
      area: area,
      role: role,
      createAt: new Date(fecha),
      status: status,
      creator: uuid.idU,
      lastAccess: Date(),
      company: newCompanies,
      image: image,
      boards: dataLabels,
    };

    await postUsers(env);
    limpiar();
    return close();
  };
  const actualizarData = async () => {
    // if (!compania)
    // return error("Debe asignar proyectos o empresas al usuario");
    let { email, name, lastname, role, fecha, status, area } =
      form.getFieldsValue();
    const newCompanies = await validarItem();
    if (!newCompanies || newCompanies.length === 0)
      return error("Debe asignar proyectos o empresas al usuario");
    let env = {
      email: email,
      name: name,
      lastname: lastname,
      area: area,
      role: role,
      createAt: new Date(fecha),
      status: status,
      company: newCompanies,
      image: image,
      boards: dataLabels,
    };
    console.log(env);
    await putUsers(env, platformState.dataUpdate?.uuid || "");

    limpiar();
    return close();
  };

  const limpiar = () => {
    form.resetFields();

    setDataLabels([]);
    setBoards([]);
    if (!platformState.dataUpdate) return;
  };
  const cargarImagenTobase64 = async () => {
    let img: any = await document.getElementById("file-input");
    let archivo = img.files[0];
    const reader: any = new FileReader();

    reader.readAsDataURL(archivo);

    reader.onload = () => {
      setImage(reader.result);
    };
  };
  const handleChangeType = (value: string) => {
    //cargarDataLabels(value);
  };
  const handleChange = (value: string) => {
    callSubcompanies(value);
  };
  const handleChangeBoard = (value: string) => {
    cargarDataLabels(value);
  };
  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };
  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };
  const cargarDataLabels = (id: string) => {
    if (id === "value") return;
    const value = boards.find((e: any) => e.uuid === id);
    let array: Array<any> = dataLabels.slice();
    let env: DataLabelsUser = {
      name: value.name,
      uuid: value.uuid,
      items: value["data_labels"],
      uuidEmpresa:
        form.getFieldValue("selectSubcompany") || form.getFieldValue("project"),
    };
    if (!dataLabels.find((e: any) => e.uuid === id)) {
      array.push(env);
      setChildrenDrawer(true);
    }
    console.log(array);
    return setDataLabels(array);
  };
  const callSubcompanies = async (text: string) => {
    if (project) {
      const data = await getSubCompanies(project);
      setSubcompanies(data);
      if(text === "boards"){
        const borads = await getBoardsForId(project);
        setBoards(borads);
      }else {
        const borads = await getDataForId(project)
        setBoards(borads);
      }
    
      if (data.length === 0) {
        setSubcompanies([]);
      }
    }
  };

  return (
    <>
      <Form
        name="wrap"
        labelCol={{ flex: "110px" }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        form={form}
        onFinish={action}
        style={{ textAlign: "center" }}
      >
        <Avatar
          style={{ marginBottom: 10 }}
          size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
          icon={<IconHeader url={image} />}
        />
        <div className="image-upload" style={{ marginBottom: 10 }}>
          <label htmlFor="file-input" onClick={() => {}}>
            {platformState.language.profileEditar} &nbsp;{" "}
            <EditOutlined width="2rem" />
          </label>
          <input
            id="file-input"
            onChange={() => cargarImagenTobase64()}
            type="file"
          />
        </div>

        <Form.Item
          label={platformState.language.profileName}
          name="name"
          rules={[{ required: true }]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label={platformState.language.profileLastName}
          name="lastname"
          rules={[{ required: true }]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label={platformState.language.profileEmail}
          name="email"
          rules={[{ required: true }]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label={platformState.language.userFormArea}
          name="area"
          rules={[{ required: true }]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label={platformState.language.userFormRol}
          name="role"
          rules={[{ required: true }]}
        >
          <Select
            defaultValue="none"
            onChange={handleChangeType}
            options={[
              { value: "none", label: platformState.language.companyTypeThree },
              ...rolesUser.map((e: any) => {
                return {
                  value: e.uuid,
                  label: e.name,
                };
              }),
            ]}
          />
        </Form.Item>

        <Form.Item
          label={platformState.language.userFormFecha}
          name="fecha"
          rules={[{ required: true }]}
        >
          <Input type="date" />
        </Form.Item>
        <Form.Item
          label={platformState.language.userFormEstado}
          name="status"
          rules={[{ required: true }]}
        >
          <Select
            defaultValue={"Activo"}
            onChange={handleChangeType}
            options={[
              { value: true, label: "Activo" },
              {
                value: false,
                label: "Inactivo",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label={platformState.language.companyTypeTwo}
          name="project"
          rules={[{ required: true }]}
        >
          <Select
            defaultValue={project}
            onChange={setProject}
            options={[
              {
                value: "none",
                label: platformState.language.companyTypeThree,
              },
              ...companies.map((e: any) => {
                return {
                  value: e.uuid,
                  label: e.name,
                };
              }),
            ]}
          />
        </Form.Item>
        <Form.Item
          label={platformState.language.companyType}
          name=""
          rules={[{ required: true }]}
        >
          <Select
            defaultValue={"none"}
            onChange={handleChange}
            options={[
              {
                value: "none",
                label: platformState.language.companyTypeThree,
              },
              {
                value: "boards",
                label: platformState.language.sidebarMisTableros,
              },
              {
                value: "vikuaBoards",
                label: platformState.language.sidebarTablerosVikua,
              },
            ]}
          />
        </Form.Item>
        {subcompanies.length > 0 && (
          <Form.Item
            label={platformState.language.userFormCompania}
            name="selectSubcompany"
          >
            <Select
              defaultValue="none"
              onChange={handleChange}
              options={[
                {
                  value: "none",
                  label: platformState.language.companyTypeThree,
                },
                ...subcompanies.map((e: any) => {
                  return {
                    value: e.uuid,
                    label: e.name,
                  };
                }),
              ]}
            />
          </Form.Item>
        )}
        {boards.length > 0 && (
          <Form.Item
            label={platformState.language.userFormProyecto}
            name="seletBoard"
          >
            <Select
              defaultValue="none"
              onChange={handleChangeBoard}
              options={[
                {
                  value: "none",
                  label: platformState.language.companyTypeThree,
                },
                ...boards.map((e: any) => {
                  return {
                    value: e.uuid,
                    label: e.name,
                  };
                }),
              ]}
            />
          </Form.Item>
        )}

        {dataLabels?.length > 0 && (
          <Form.Item label=" " style={{ textAlign: "end" }}>
            <Button
              type="primary"
              htmlType="button"
              onClick={showChildrenDrawer}
            >
              Tableros
            </Button>
          </Form.Item>
        )}

        <Form.Item label=" " style={{ textAlign: "end" }}>
          <Button type="primary" htmlType="submit">
            {platformState.botonModalForm}
          </Button>
        </Form.Item>
      </Form>
      <Drawer
        title="Tableros asignados"
        width={420}
        closable={true}
        onClose={onChildrenDrawerClose}
        open={childrenDrawer}
      >
        <DataLabelsAsig
          dataLabels={dataLabels}
          setDataLabels={setDataLabels}
          company={
            form.getFieldValue("selectSubcompany") ||
            form.getFieldValue("project")
          }
          cerrar={onChildrenDrawerClose}
        />
      </Drawer>
    </>
  );
};

export default FormsUsers;
