
import { api } from '../environment';
import { Tracking } from '../interfaces/tracking';
import { types } from '../types/types';
import { error, success } from './alertsServices';
import { postTracking } from './tracking';

let tracking: Tracking = {
  uuidSesion: "",
  activity: "",
  description: ""
}

const getUsers = async (id: string, skip: any, limit: any, search: string) => {
  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') || ""
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    }
  }

  try {

    tracking.activity = "Get Users"
    tracking.description = "Get all users"
    tracking.uuidSesion = session
    await postTracking(tracking)

    const login = await fetch(api + "users/" + id + `?limit=${limit}&offset=${skip}&search=${search}`, options
    );
    const data = await login.json();
    return data;
  } catch (e) {
    return e;
  }
}
const getUsersForCompany = async (id: string) => {
  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') || ""
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    }
  }

  try {

    tracking.activity = "Get Users for companies"
    tracking.description = "Get all users for companies"
    tracking.uuidSesion = session
    await postTracking(tracking)

    const login = await fetch(api + "users/company/" + id, options
    );
    const data = await login.json();
    return data;
  } catch (e) {
    return e;
  }
}

const postUsers = async (data: any) => {
  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') || ""
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    },
    body: JSON.stringify(data)
  }

  try {
    tracking.activity = "Create Users"
    tracking.description = "Create a new users"
    tracking.uuidSesion = session
    await postTracking(tracking)

    const login = await fetch(api + "users", options
    );
    const data = await login.json();
    if (data.error) {
      error("No se creo el usuario")
    } else {
      success("Usuario creado con exito")
    }

    return data;
  } catch (e) {

    error("No se creo el usuario")
    return e;
  }
}

const putUsers = async (data: any, id: string) => {
  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') || ""
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    },
    body: JSON.stringify(data)
  }

  try {
    tracking.activity = "Update Users"
    tracking.description = "Update a users"
    tracking.uuidSesion = session
    await postTracking(tracking)

    const login = await fetch(api + "users/" + id, options
    );
    const data = await login.json();
    if (data.error) {
      error("No se creo el usuario")
    } else {
      success("Usuario creado con exito")
    }

    return data;
  } catch (e) {

    error("No se creo el usuario")
    return e;
  }
}
const putAvatarProfile = async (data: any, id: string) => {
  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') || ""
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    },
    body: JSON.stringify(data)
  }

  try {

    tracking.activity = "Update Profile"
    tracking.description = "Update a profile"
    tracking.uuidSesion = session
    await postTracking(tracking)

    const login = await fetch(api + "users/avatar/" + id, options
    );
    const data = await login.json();
    if (data.error) {
      error("No se creo el usuario")
    } else {
      success("Usuario creado con exito")
    }

    return data;
  } catch (e) {

    error("No se creo el usuario")
    return e;
  }
}
const putProfile = async (data: any, id: string) => {
  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') || ""
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    },
    body: JSON.stringify(data)
  }

  try {

    tracking.activity = "Update Profile"
    tracking.description = "Update a profile"
    tracking.uuidSesion = session
    await postTracking(tracking)

    const login = await fetch(api + "users/profile/" + id, options
    );
    const data = await login.json();
    if (data.error) {
      error("No se creo el usuario")
    } else {
      success("Usuario creado con exito")
    }

    return data;
  } catch (e) {

    error("No se creo el usuario")
    return e;
  }
}
const deleteUsers = async (id: string, dispatch: any) => {
  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') || ""
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    }
  }

  try {
    tracking.activity = "Delete Users"
    tracking.description = "Delete a users"
    tracking.uuidSesion = session
    await postTracking(tracking)

    const login = await fetch(api + "users/" + id, options
    );
    const data = await login.json();
    if (data.error) {

    } else {
      dispatch({
        type: types.test,
        payload: Math.random()
      })
    }

    return data;
  } catch (e) {

    error("No se creo el usuario")
    return e;
  }
}

const getUsersForCompanies = async (data: any) => {

  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') || ""
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    },
    body: JSON.stringify(data)
  }

  try {
      
    tracking.activity = "Get Users for companies"
    tracking.description = "Get all users for companies"
    tracking.uuidSesion = session
    await postTracking(tracking)

    const login = await fetch(api + "users/companies", options
    );
    const data = await login.json();


    return data;
  } catch (e) {

    error("No se creo el usuario")
    return e;
  }
}
const getProfile = async (id:any) => {
  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') || ""
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    }
  }

  try {

    tracking.activity = "Get Users"
    tracking.description = "Get all users"
    tracking.uuidSesion = session
    await postTracking(tracking)

    const login = await fetch(api + "users/profile/"+id , options
    );
    const data = await login.json();
    return data;
  } catch (e) {
    return e;
  }
}
export {
  getUsers, postUsers, putUsers, putProfile, getProfile,
  deleteUsers, getUsersForCompany, getUsersForCompanies, putAvatarProfile
}