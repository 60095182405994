import { useEffect, useState, useContext } from "react";
import { getMyBoards } from "../services/myboardsServices";
import { PlatformContext } from "../context/platformContext";




export const useMyBoards = () => {
    const [boards, setBoards]: any = useState(null)
    const { platformState, dispatch }: any = useContext(PlatformContext)
    async function data() {
        setBoards([])
        if(localStorage.getItem("session")){
            const dato: any = await getMyBoards(platformState.limit, platformState.skip, platformState.search)
          
            if(!dato || dato.message === 'Unauthorized'){
                
              return localStorage.clear();
            }        
            return setBoards(dato || [])
        }
      
   
        return setBoards([])
    }
    useEffect(() =>{
        
        data()
       
    },[platformState.openModal, platformState.test, platformState.skip, platformState.search] )

    return  { boards }
}

