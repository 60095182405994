import { useState, useEffect, useContext } from "react";
import { useCompanies } from "../../hooks/companiesHook";
import { PlatformContext } from "../../context/platformContext";
import { types } from "../../types/types";
import "../../styles/modal.css";
import { DataLabels } from "../../interfaces/dataLabels";
import DataLabel from "../../components/datalabels/dataLabels";
import { Avatar, Button, Drawer, Form, Input, Select } from "antd";
import { IconHeader } from "../../components/icons/iconHeader";
import { EditOutlined } from "@ant-design/icons";
import { avatar } from "../../environment";
import { postMyBoards, updateMyBoards } from "../../services/myboardsServices";


const FormsMyBoars = ({ close }: any) => {
  const { platformState, dispatch }: any = useContext(PlatformContext);
  const { companies } = useCompanies();
  const [test, setTest] = useState(true);
  const [image, setImage] = useState("");
  const [dataLabels, setDataLabels] = useState<Array<DataLabels>>([]);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (platformState.dataUpdate) {
      setImage( platformState.dataUpdate.image)
      form.setFieldValue("name", platformState.dataUpdate.name);
      form.setFieldValue("description", platformState.dataUpdate.description);
      form.setFieldValue("url", platformState.dataUpdate.url);
      form.setFieldValue("project", platformState.dataUpdate.asignado);
      setDataLabels(platformState.dataUpdate["data_labels"]);
      setTest(false);
    } else {
      limpiar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformState.dataUpdate]);

  const sendData = async () => {
    let { name, description, url, project } = form.getFieldsValue();
    let env = {
      name: name,
      description: description,
      url: url,
      image: image,
      asignado: project,
      data_labels: dataLabels,
    };
    // alert(JSON.stringify(env))

    await postMyBoards(env);
    limpiar();
    return dispatch({
      type: types.openModal,
      payload: false,
    });
  };

  const updateData = async () => {
    let { name, description, url, project } = form.getFieldsValue();
    let env = {
      name: name,
      description: description,
      url: url,
      image: image,
      asignado: project,
      data_labels: dataLabels,
    };

    await updateMyBoards(env, platformState.dataUpdate.uuid);
    limpiar();
    return dispatch({
      type: types.openModal,
      payload: false,
    });
  };

  function limpiar() {
    form.resetFields();

    setDataLabels([]);
    setImage(avatar);

    dispatch({
      type: types.dataUpdate,
      payload: null,
    });
  }

  const cargarImagenTobase64 = async () => {
    let img: any = await document.getElementById("file-input");
    let archivo = img.files[0];
    const reader: any = new FileReader();

    reader.readAsDataURL(archivo);

    reader.onload = () => {
      setImage(reader.result);
    };
  };

  const action = async () => {
    if (!platformState.dataUpdate) {
      return await sendData();
    }
    return await updateData();
  };
  const handleChange = (value: string) => {
    cargarDataLabels(value);
  };
  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };
  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };

  const cargarDataLabels = (e: string) => {};
  return (
    <>
      <Form
        name="wrap"
        labelCol={{ flex: "110px" }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        form={form}
        onFinish={action}
        style={{ textAlign: "center" }}
      >
        <Avatar
          style={{ marginBottom: 10 }}
          size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
          icon={<IconHeader url={image} />}
        />
        <div className="image-upload" style={{ marginBottom: 10 }}>
          <label htmlFor="file-input" onClick={() => {}}>
            {platformState.language.profileEditar} &nbsp;{" "}
            <EditOutlined width="2rem" />
          </label>
          <input
            id="file-input"
            onChange={() => cargarImagenTobase64()}
            type="file"
          />
        </div>
        <Form.Item
          label={platformState.language.modalBoardasName}
          name="name"
          rules={[{ required: true }]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label={platformState.language.modalBoardasDescription}
          name="description"
          rules={[{ required: true }]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label={platformState.language.modalBoardasUrl}
          name="url"
          rules={[{ required: true }]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label={platformState.language.companyTypeTwo}
          name="project"
          rules={[{ required: true }]}
        >
          <Select
            defaultValue="none"
            onChange={handleChange}
            options={[
              {
                value: "none",
                label: platformState.language.companyTypeThree,
              },
              ...companies.map((e: any) => {
                return {
                  value: e.uuid,
                  label: e.name,
                };
              }),
            ]}
          />
        </Form.Item>

   
          <Form.Item label=" " style={{ textAlign: "end" }}>
            <Button
              type="primary"
              htmlType="button"
              onClick={showChildrenDrawer}
            >
              campos adicionales
            </Button>
          </Form.Item>
       

        <Form.Item label=" " style={{ textAlign: "end" }}>
          <Button type="primary" htmlType="submit">
            {platformState.botonModalForm}
          </Button>
        </Form.Item>
      </Form>
      <Drawer
        title="Campos adicionales"
        width={420}
        closable={true}
        onClose={onChildrenDrawerClose}
        open={childrenDrawer}
      >
        <DataLabel
          platformState={platformState}
          setDataLabels={setDataLabels}
          dataLabels={dataLabels}
        />
      </Drawer>
    </>
  );
};

export default FormsMyBoars;
